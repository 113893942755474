<template>
  <div class="wrap-eHeader" :class="editable? 'wr-else':''">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FloatTitBtn",
  data() {
    return {
      editable: false,
    }
  },
  mounted () {
    var arr = Object.keys(this.$route.query);
    if(arr.length == 0){
      this.editable = true;
    }
  },
};
</script>
<style>
.wrap-eHeader{
  position: absolute;
  z-index: 999;
  height: 45px;
  margin-top: -20px;
  width: calc(100% - 56px);
  background-color: #fff;
  border-bottom: 1px solid #DCDFE6;
}
.wrap-eHeader .el-button{
  padding: 10px 20px;
  min-height: 30px;
}
.wr-else{
  width: calc(100% - 76px);
}
.edit-header{
  height: 35px;
  margin-top: 5px;
}
.el-card {
  border: 0px;
}
.wrap-edit {
  height: 100%;
  overflow-y: scroll;
}
.edit-container{
  margin-top: 40px;
}
</style>