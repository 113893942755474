<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>流程信息</span>
      </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-steps :active="active" finish-status="success">
                <el-step
                  :title="step"
                  v-for="(step, index) in steps"
                  :key="index"
                ></el-step>
              </el-steps>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <PublicTable ref="publicTable"></PublicTable>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import PublicTable from '../../components/table/PublicTable.vue'
// import { ElMessage } from 'element-plus'
import axios from "axios";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'lcxx',
  data() {
    return {
      labelPosition: 'left',
      bjid: '',
      fid: '',
      active: 0,
      queryId: 'taskprocess_list',
      steps: ['', '', '', '', '', ''],
      thisstep: '',
    }
  },
  components: {
    PublicTable,
    FloatTitBtn,
  },
  methods: {
    //转化json
    switch() {
      let me = this
      me.bjid = this.$route.query.instanceId
      me.fid = this.$route.query.fid
      if (me.bjid && me.fid) {
        let pm = []
        pm.push(axios.get(me.$appConfig.apiUrls.flowApi + '/api/WorkFlow/GetStepList?workFlowId=' + me.fid));
        pm.push(axios.post(me.$appConfig.apiUrls.flowApi + '/api/WorkFlowStep/GetCurrentStepByInstanceId?instanceId=' + me.bjid));
        axios.all(pm).then(
          axios.spread((result1, result2) => {
            if (result2.data.IsSuccess) {
              if (result1.data.IsSuccess) {
                let arr = []
                result1.data.Data.forEach((v, i) => {
                  if (result2.data.Data.Name == v.Name) {
                    me.thisstep = i
                  }
                  arr.push(v.Name)
                })
                me.steps = [...arr]

              }
              me.active = ++me.thisstep
            }
          })
        );
      }
    },
    rendertable() {
      let me = this
      let conditions = [
        {
          Key: 'InstanceId',
          Value: this.$route.query.instanceId,
          IsCondition: true,
          Operator: '=',
          LogicalOperator: 'and',
        },
      ]
      me.$refs.publicTable.getLoadData(me.queryId, conditions)
    },
  },
  mounted() {
    this.rendertable()
    this.switch()
  },
  activated() {
  },
}
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
