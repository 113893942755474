<template>
  <!-- 公用表格 -->
  <div class="table-body">
    <el-table
      ref="table"
      :data="tableData"
      stripe
      :border="true"
      row-key="Id"
      :height="isFixed ? fixedHeight : tableHeight"
      style="width: 100%"
      v-loading="isLoading"
      element-loading-text="数据加载中..."
      :default-expand-all="false"
      :header-row-class-name="tableRowClassName"
      :row-class-name="isOvertime2"
      @sort-change="sortChange"
      @expand-change="exChange"
    >
      <!-- 是否有含有子内容的第一列 -->
      <el-table-column type="expand" v-if="showChild === true">
        <slot name="expand-child"></slot>
      </el-table-column>
      <template v-for="(item, index) in tableHeaders" :key="index">
        <!--不带插槽的列-->
        <el-table-column
          :sortable="isSortable"
          :prop="item.field"
          :label="item.title"
          v-if="item.slotName == ''"
          :min-width="item.width"
          label-class-name="tes0"
          show-overflow-tooltip
        />
        <!--带插槽的列 有固定列的列-->
        <el-table-column
          :sortable="isSortable"
          v-else-if="item.slotName == 'td_ID' || item.slotName == 'td_Id'"
          :width="item.width > 0 ? item.width : 170"
          fixed="right"
          :prop="item.field"
          :label="item.title"
          label-class-name="tes0"
          show-overflow-tooltip
        >
          <template #default="scope">
            <slot :name="item.slotName" v-bind="scope"></slot>
          </template>
        </el-table-column>
        <!--带插槽的列-->
        <el-table-column
          v-else
          :sortable="isSortable"
          :prop="item.field"
          :label="item.title"
          :min-width="item.width"
          label-class-name="tes0"
          show-overflow-tooltip
        >
          <template #default="scope">
            <slot :name="item.slotName" v-bind="scope"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
  <div class="table-footer">
    <div class="footer-left">
      {{ para.PageInfo.pageSize * (para.PageInfo.pageNum - 1) + 1 }}-{{
        para.PageInfo.pageNum * para.PageInfo.pageSize > total
          ? total
          : para.PageInfo.pageNum * para.PageInfo.pageSize
      }}
      / {{ total }}
    </div>
    <el-pagination
      background
      @current-change="handlePageChange"
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="para.PageInfo.pageSize"
      :pager-count="5"
    >
    </el-pagination>
  </div>
</template>

<script>
import { useSlots } from 'vue'
export default {
  props: {
    isSortable: {
      type: Boolean,
      default: false
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    fixedHeight: {
      type: Number,
      default: 0
    },
    showChild: {
      type: Boolean,
      default: false
    },
    tableHeaders2: {
      type: Array,
      default: function () {
        return [];
      }
    },
  },
  data() {
    return {
      url: this.$appConfig.apiUrls.tableApi,
      slots: useSlots(), // 获取插槽信息
      tableHeaders: [], //表头数据
      tableData: [], //表格数据
      isLoading: true, // 表格的loading效果
      total: 0, // 总数
      para: {
        queryId: '',
        PageInfo: { pageNum: 1, pageSize: 10 },
        conditions: null,
      },
      tableHeight: '100%',
      cName: 'jsxm',
    }
  },
  emits: ['expand-change', 'changePage', 'changeScroll'], //vue3子传父需写
  methods: {
    sortChange(row){
      if(row.order==='ascending'){
        this.para.sortInfo= row.prop+" asc";
      }else if(row.order==='descending'){
        this.para.sortInfo= row.prop+" desc";
      }else{
        delete this.para.sortInfo;
      }
      this.getDataList(this.para)
    },
    //获取表头数据
    getLoadData(queryId, conditions,PageInfo) {
      if(PageInfo){
        this.para.PageInfo = PageInfo
      }
      this.para.queryId = queryId
      if (conditions === []) conditions = null
      this.para.conditions = conditions
      let me = this
      if (me.tableHeaders.length > 0) {
        me.getDataList(me.para)
      } else {
        this.$ajax
          .get(this.$appConfig.apiUrls.tableApi + '/api/CommonTable/GetFileds?queryKey=' + queryId)
          .then(function (response) {
            if (response.data.isSuccess) {
              me.tableHeaders = response.data.data
              me.getDataList(me.para)
            } else {
              console.log(response.data.errorMessage)
            }
          })
      }
    },
    //获取表格数据
    async getDataList(para) {
      if (para.queryId === 'IntegratedQue_new' || para.queryId === 'taskprocess_list') {
        this.cName = para.queryId;
        this.tableHeight = this.windowHeight - 460
        if (this.windowHeight >= 842) {
          this.tableHeight = 462
        }
      } else if (this.cName === 'jsxm') {
        this.tableHeight = this.windowHeight - 220
        if (this.windowHeight >= 620) {
          this.tableHeight = 398
        }
      }
      this.para = para
      let me = this
      this.isLoading = true
      var res = await this.$ajax.post(
        me.url + '/api/CommonTable/LoadData',
        JSON.stringify(para)
      )
      res = JSON.parse(res.data.data)
      me.tableData = res.rows
      me.total = res.total
      me.isLoading = false
      // 检查插槽，设置名称
      this.tableHeaders.forEach(col => {
        const _slotName = 'td_' + col.field
        if (typeof this.slots[_slotName] === 'function') {
          // 有插槽
          col.slotName = _slotName
        } else {
          // 没有插槽
          col.slotName = ''
        }
      })
      this.$emit("changeScroll", true);
    },
    // 获取表格数据 父组件获取
    getTData(para, res) {
      this.para = para
      if (para.queryId.split('_')[0] === 'lahz') {
        this.cName = ''
        if (this.windowWidth < 768) {
          this.tableHeight = this.windowHeight - 690
        } else if (this.windowWidth < 990) {
          this.tableHeight = this.windowHeight - 485
        } else if (this.windowWidth < 1200) {
          this.tableHeight = this.windowHeight - 440
        } else if (this.windowWidth > 1200) {
          this.tableHeight = this.windowHeight - 320
          if (this.windowHeight >= 783) {
            this.tableHeight = 462
          }
        }
      }else{
        this.changeTableHeight()
      }
      this.tableData = res.rows
      this.total = res.total
      this.tableHeaders = this.tableHeaders2;
      this.tableHeaders.forEach(col => {
        const _slotName = 'td_' + col.field
        if (typeof this.slots[_slotName] === 'function') {
          // 有插槽
          col.slotName = _slotName
        } else {
          // 没有插槽
          col.slotName = ''
        }
      })
      this.isLoading = false
    },
    async exChange(row, rowList) {
      this.$emit('expand-change', row, rowList)
    },
    // 分页
    handlePageChange(val) {
      if (this.tableHeaders2.length === 0) {
        this.para.PageInfo.pageNum = val
        this.getDataList(this.para)
      } else {
        this.isLoading = true
        this.$emit("changePage", val);
      }
    },
    //给表头加下划线
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 0) {
        return 'header-row'
      }
      return ''
    },
    //导出Excel表格
    async exportExcel(queryId, conditions, fileName = '导出表格') {
      let me = this
      let res = await this.$ajax.post(
        this.url + '/api/CommonTable/ExportData',
        JSON.stringify({ queryId, conditions })
      )
      if (res.data.isSuccess) {
        window.location.href = me.url + '/api/CommonTable/DownExport?filename=' + res.data.data + '&tableName=' + fileName
      }
    },
    isOvertime2(row) {
      if (row.row.JJRQ && row.row.WorkTime) {
        const jjsj = new Date(row.row.JJRQ)
        let jzsj = jjsj.setDate(jjsj.getDate() + row.row.WorkTime)
        if (jzsj < new Date()) {
          return 'danger-row'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    toggleRowExpansion(row) {
      this.$refs.table.toggleRowExpansion(row)
    },
    changeTableHeight() {
      let that = this;
      window.fullWidth = document.documentElement.clientWidth;
      that.windowWidth = window.fullWidth; // 宽
      window.fullHeight = document.documentElement.clientHeight;
      that.windowHeight = window.fullHeight; // 高
      if (that.cName === 'IntegratedQue_new') {
        that.tableHeight = that.windowHeight - 460
        if (this.windowHeight >= 842) {
          this.tableHeight = 462
        }
      } else if (that.cName === 'jsxm') {
        that.tableHeight = that.windowHeight - 220
        if (that.windowHeight >= 620) {
          that.tableHeight = 398
        }
      } else {
        if (that.windowWidth < 768) {
          that.tableHeight = that.windowHeight - 690
        } else if (that.windowWidth < 990) {
          that.tableHeight = that.windowHeight - 485
        } else if (that.windowWidth < 1200) {
          that.tableHeight = that.windowHeight - 440
        } else if (that.windowWidth > 1200) {
          that.tableHeight = that.windowHeight - 320
          if (this.windowHeight >= 783) {
            this.tableHeight = 462
          }
        }
      }
    }
  },
  activated() {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;
    window.addEventListener('resize', this.changeTableHeight)
  },
  deactivated() {
    window.removeEventListener('resize', this.changeTableHeight)
  }
}
</script>

<style>
.tes0 {
  color: #000000;
}
.el-table__expanded-cell[class*="cell"] {
  padding: 0px 50px;
}
.el-table .header-row th {
  /* background: rgb(235, 161, 161)!important; */
  border-bottom: 1px #ebe8e8 solid;
  background: #f2f4f4 !important;
}
.el-table .header-row th > .cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-table th,
.el-table td {
  padding: 6px 0;
  background: #ffffff;
}
</style>

<style scoped>
.tes {
  color: #3c8dbc;
  cursor: pointer;
}

.el-table tr {
  color: #000000;
}

.table-footer {
  padding: 7px;
  overflow: hidden;
  border-right: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  background: #ffffff;
}
.footer-left {
  float: left;
  font-size: 13px;
  color: var(--el-text-color-regular);
}
.el-pagination {
  float: right;
}
.el-pagination :deep(.btn-prev .el-icon), .el-pagination :deep(.btn-next .el-icon) {
  display: inline-block;
}

.el-table .header-row th {
  /* background: rgb(235, 161, 161)!important; */
  border-bottom: 1px #ebe8e8 solid;
  background: #f2f4f4 !important;
}
.el-table .header-row th > .cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:deep(.el-table.danger-row) {
  color: rgb(194, 15, 15);
}
</style>




